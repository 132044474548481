<div class="failed-order-card" *ngIf="!viewState.isNoFailedOrders">
  <div class="failed-order-card__content">
    <div class="failed-order-card__upper">
      <p class="content-medium-color body1--medium">{{ viewState.status | translate }}</p>
      <p class="body2--bold failed-status">
        {{ 'FAILED_ORDERS.FAILED' | translate }}
      </p>
      <p class="content-medium-color body1--medium">
        {{ 'FAILED_ORDERS.CREATED_AT' | translate }}
      </p>
      <p class="content-main-color body2--bold">
        {{ viewState.FailedOrder.createdAt | date : dateFormat }}
      </p>
      <p *ngIf="viewState.FailedOrder?.orderReceivedBy" class="content-medium-color body1--medium">
        {{ 'FAILED_ORDERS.SOURCE' | translate }}
      </p>
      <p *ngIf="viewState.FailedOrder?.orderReceivedBy" class="content-main-color body2--bold">
        {{ viewState.FailedOrder?.orderReceivedBy | translate }}
      </p>
    </div>
    <div class="failed-order-card__lower">
      <div class="same-column">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.CUSTOMER_NAME' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder.receiverName }}</p>
      </div>
      <div class="same-column">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.CUSTOMER_NUMBER' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder.phoneNumber }}</p>
      </div>
      <div class="same-column" *ngIf="viewState.FailedOrder?.phoneNumber2">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.CUSTOMER_NUMBER2' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder?.phoneNumber2 }}</p>
      </div>
      <div class="same-column">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.PROVINCE' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder.province }}</p>
      </div>
      <div class="same-column" *ngIf="viewState.FailedOrder?.totalPrice">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.TOTAL_PRICE' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder.totalPrice }}</p>
      </div>
      <div class="same-column" *ngIf="viewState.FailedOrder?.totalProfit">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.ORDER_PROFIT' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder.totalProfit }}</p>
      </div>
    </div>
    <div class="failed-order-card__lower">
      <div class="same-column">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.CUSTOMER_ADDRESS' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder.address }}</p>
      </div>
      <div class="same-column">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.SHIPPED_TO' | translate }}
        </p>
        <p class="content-main-color body2--bold">
          {{ viewState.FailedOrder.country | translate }}
        </p>
      </div>
      <div class="same-column" *ngIf="viewState.FailedOrder?.notes">
        <p class="content-medium-color body1--medium">
          {{ 'FAILED_ORDERS.NOTES' | translate }}
        </p>
        <p class="content-main-color body2--bold">{{ viewState.FailedOrder.notes }}</p>
      </div>
    </div>

    <div class="failure-reason">
      <div class="failure-reason__header">
        <img src="assets/img/red-error.svg" />
        <p class="content-main-color body2--bold">
          {{ 'FAILED_ORDERS.FAILED_ERROR' | translate }}
        </p>
      </div>
      <p class="content-main-color body2--regular">
        {{ viewState.FailedOrder.failureReasonCode | translate }}
      </p>
    </div>
  </div>

  <div class="failed-order-card__btn" *ngIf="isViewFailedOrderDetailsBtnEnabled">
    <button class="btn btn-viewOrder" (click)="onItemsDetails()">
      {{ 'ORDERS_PAGE.SHOW_ORDER_BTN_LABEL' | translate }}
    </button>
  </div>
</div>

<div class="empty-message" *ngIf="viewState.isNoFailedOrders">
  <img src="assets/img/empty-leads.svg" />
  <p class="body1--bold content-main-color">
    {{ 'FAILED_ORDERS.NO_FAILED_ORDER' | translate }}
  </p>
  <p class="body2--regular content-medium-color">
    {{ 'FAILED_ORDERS.WILL_DISPLAY' | translate }}
  </p>
</div>
